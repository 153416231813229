<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Nuevo Pago</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <company-field
                  :textLabel="'Sucursal Origen'"
                  v-model="companyId"
                  @value="(val) => (companyId = val)"
                ></company-field>
              </v-col>
              <v-col cols="12" md="6">
                <internal-account-field
                  v-model="accountInternal"
                  :isRequired="true"
                  :sourceCompany="companyId"
                  :reset="resetAccountInternal"
                ></internal-account-field>
              </v-col>
            </v-row>
            <!-- simple table account internal -->
            <v-row>
              <v-col cols="12" md="12">
                <v-simple-table
                  dense
                  v-if="accountInternal != null && accountInternal != undefined"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Sucursal Origen</th>
                        <th class="text-left">Sucursal Destino</th>
                        <th class="text-left">Saldo</th>
                        <th class="text-left">Ultima actualización</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ accountInternal.source_company.second_name }}</td>
                        <td>{{ accountInternal.destination_company.second_name }}</td>
                        <td>{{ accountInternal.balance | currency }}</td>
                        <td>{{ accountInternal.updated | date }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table></v-col
              >
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="6">
                <billings-field
                  v-model="specificBilling"
                  :accountID="accountId"
                  :reset="resetBilling"
                ></billings-field>
              </v-col>
            </v-row>
          </v-form>
          <!-- cobros -->
          <p class="text-left text--disabled" v-if="showBillings">
            Detalle de abonos a cobros
          </p>
          <br />
          <v-data-table
            v-if="showBillings"
            :headers="headers"
            :items="details"
            item-key="index"
            class="elevation-1"
            hide-default-footer
            disable-sort
            disable-pagination
          >
            <template v-slot:item.amount="{ item }">
              <span>{{ item.amount | currency }}</span>
            </template>
            <template v-slot:item.balance="{ item }">
              <span>{{ item.balance | currency }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="removeDetail(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.pay="{ item }">
              <v-text-field
                v-model="item.pay"
                type="number"
                step="any"
                :rules="decimalRequired"
                ref="rpay"
              ></v-text-field>
            </template>
          </v-data-table>
          <v-row class="d-flex" v-if="showBillings">
            <v-col cols="12" md="6" class="text-center">
              <span>Total: </span>
            </v-col>
            <v-col cols="12" md="6">
              <span>
                <b>{{ totalBillings | currency }}</b></span
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <!-- detalle de pagos -->
          <p class="text-left text--disabled" v-if="showPayment">Detalle del pago</p>
          <v-form ref="formPayment" v-model="validPaymentForm" lazy-validation>
            <v-row v-if="showBillings">
              <v-col cols="12" md="2">
                <v-select
                  v-model="paymentMethod.type"
                  :items="methods"
                  label="Método de pago"
                  :rules="onlyRequired"
                  ref="rpaym"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" v-if="paymentMethod.type != 'DESCUENTO'">
                <v-text-field
                  v-model="paymentMethod.description"
                  :label="documentLabel"
                  :rules="onlyRequired"
                  ref="rdoc"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                v-if="
                  paymentMethod.type == 'CHEQUE' || paymentMethod.type == 'TRANSFERENCIA'
                "
              >
                <v-text-field
                  v-model="paymentMethod.account"
                  label="Banco"
                  :rules="onlyRequired"
                  ref="racct"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" v-if="paymentMethod.type == 'TRANSFERENCIA'">
                <v-text-field
                  v-model="paymentMethod.account_code"
                  label="No. Cuenta"
                  :rules="onlyRequired"
                  ref="rnoacct"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="paymentMethod.amount"
                  label="Monto"
                  :rules="decimalRequired"
                  ref="ramount"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn depressed small color="primary" class="mt-5" @click="addPayment()">
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            v-if="showPayment"
            :headers="headersPayment"
            :items="paymentMethods"
            class="elevation-1"
            hide-default-footer
            disable-sort
            disable-pagination
          >
            <template v-slot:item.amount="{ item }">
              <span>{{ item.amount | currency }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="removePaymentMethod(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-row class="d-flex" v-if="showPayment">
            <v-col cols="12" md="6" class="text-center">
              <span>Total: </span>
            </v-col>
            <v-col cols="12" md="4" class="text-end"
              ><span
                ><b>{{ totalPayment | currency }}</b></span
              >
            </v-col>
          </v-row>
          <br />
          <v-row class="d-flex flex-row-reverse">
            <v-btn color="success" @click="save()" :disabled="!showSave"> Guardar</v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <detail-payment
      v-if="dialogPayment"
      :dialog="dialogPayment"
      :paymentId="paymentId"
      @close="(dialogPayment = false), (paymentId = null), $emit('close')"
    ></detail-payment>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules.js";
export default {
  name: "BillingForm",
  components: {
    InternalAccountField: () => import("@/components/fields/AccountInternalField.vue"),
    BillingsField: () => import("@/components/fields/BillingsField.vue"),
    CompanyField: () => import("@/components/fields/CompanyField.vue"),
    DetailPayment: () => import("@/components/dealings/DetailPayment.vue"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      documentLabel: "No. Documento",
      paymentId: null,
      dialogPayment: false,
      showSave: false,
      showPayment: false,
      showBillings: false,
      showSelectBillings: false,
      companyId: 0,
      // valid
      valid: false,
      validPaymentForm: false,
      accountInternal: null,
      specificBilling: null,
      accountId: "",
      headers: [
        { text: "No. Cobro", value: "code" },
        { text: "Monto", value: "amount" },
        { text: "Saldo", value: "balance" },
        { text: "Abono", value: "pay" },
        { text: "", value: "actions" },
      ],
      headersPayment: [
        { text: "Método de pago", value: "type" },
        { text: "Documento", value: "document" },
        { text: "Monto", value: "amount" },
        { text: "", value: "actions" },
      ],
      paymentMethod: {
        type: "",
        description: "",
        account: "",
        amount: "",
        account_code: "",
      },
      methods: [
        { text: "EFECTIVO", value: "EFECTIVO" },
        { text: "CHEQUE", value: "CHEQUE" },
        { text: "TRANSFERENCIA", value: "TRANSFERENCIA" },
        { text: "DESCUENTO", value: "DESCUENTO" },
      ],
      paymentMethods: [],
      details: [],
      totalBillings: 0,
      totalPayment: 0,
      // reset
      resetAccountInternal: false,
      resetBilling: false,
      //rules
      onlyRequired: [rules.required],
      decimalRequired: [rules.required, rules.minValue(0.01)],
      body: {},
    };
  },
  methods: {
    resetAllForm() {
      this.details = [];
      this.accountInternal = null;
      this.resetAccountInternal = this.resetAccountInternal ? false : true;
      this.totalBillings = 0;
      this.totalPayment = 0;
      this.paymentMethods = [];
    },
    resetBillingForm() {
      this.specificBilling = null;
      this.resetBilling = this.resetBilling ? false : true;
      this.details = [];
      this.totalBillings = 0;
    },
    addPayment() {
      if (this.$refs.formPayment.validate()) {
        this.paymentMethods.push(this.paymentMethod);
        this.paymentMethod = {
          type: "",
          description: "",
          account: "",
          amount: "",
          account_code: "",
        };
        this.$refs.rpaym.reset();
        if (this.$refs.rdoc != undefined) {
          this.$refs.rdoc.reset();
        }
        if (this.$refs.racct != undefined) {
          this.$refs.racct.reset();
        }
        if (this.$refs.rnoacct != undefined) {
          this.$refs.rnoacct.reset();
        }
        this.$refs.ramount.reset();
      }
    },
    removePaymentMethod(item) {
      this.paymentMethods.splice(this.paymentMethods.indexOf(item), 1);
    },
    close() {
      this.$emit("close");
      this.$emit("update");
    },
    addDetail(val) {
      if (val != null && val != undefined) {
        let exist = this.details.find((x) => x.id == val.id);
        if (exist) {
          this.$toasted.global.info({ message: "El cobro ya se encuentra agregado" });
          return;
        }
        this.details.push(val);
        this.resetBilling = this.resetBilling ? false : true;
      }
    },
    removeDetail(data) {
      let index = this.details.indexOf(data);
      this.details.splice(index, 1);
    },
    save() {
      this.loading = true;
      if (Number(this.totalPayment) != Number(this.totalBillings)) {
        this.$toasted.global.info({
          message: "El total de los cobros debe ser igual al total de los pagos",
        });
        return;
      }
      if (this.paymentMethods.length == 0) {
        this.$toasted.global.info({ message: "Debe agregar al menos un método de pago" });
        return;
      }

      if (this.details.length == 0) {
        this.$toasted.global.info({ message: "Debe agregar al menos un cobro" });
        return;
      }
      if (this.accountInternal == undefined || this.accountInternal == null) {
        this.$toasted.global.info({ message: "Debe seleccionar una cuenta interna" });
        return;
      } else {
        this.body = {
          internal_account_id: this.accountInternal.id,
        };
        let detail_data = [];
        let payment_data = [];

        this.details.forEach((el) => {
          detail_data.push({
            internal_billing_id: el.id,
            amount: el.pay,
          });
        });
        this.body.details = detail_data;

        this.paymentMethods.forEach((el) => {
          if (el.type == "DESCUENTO")
            payment_data.push({
              payment_type: el.type,
              amount: el.amount,
            });
          else
            payment_data.push({
              payment_type: el.type,
              document: el.document,
              amount: el.amount,
            });
        });
        this.body.payment_methods = payment_data;
        requests
          .post("/api/bussiness-connector/internal-payment/", this.body)
          .then((res) => {
            if (res.status == 201) {
              this.$toasted.global.info({ message: "Pago guardado" });
              // this.$emit("close");
              this.paymentId = res.data.id;
              this.dialogPayment = true;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log("ERROR", error);
          });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    showBtnSave() {
      if (this.paymentMethods.length == 0 || this.details.length == 0) {
        this.showSave = false;
        return;
      } else if (
        Number(this.totalPayment) != Number(this.totalBillings) ||
        Number(this.totalPayment) <= 0 ||
        Number(this.totalBillings) <= 0
      ) {
        this.showSave = false;
        return;
      } else if (
        this.accountInternal == undefined ||
        this.accountInternal == null ||
        this.accountInternal == ""
      ) {
        this.showSave = false;
        return;
      } else {
        this.showSave = true;
        return;
      }
    },
  },
  watch: {
    companyId(newVal, oldVal) {
      if (newVal != undefined && oldVal != undefined && oldVal != newVal) {
        this.resetAllForm();
      }
      if (typeof newVal === "object") {
        this.companyId = this.companyId.id;
      }
      this.showBtnSave();
    },
    accountInternal: function (newVal, oldVal) {
      if (newVal != undefined && oldVal != undefined && oldVal.id != newVal.id) {
        this.resetBillingForm();
      }
      if (newVal != null) {
        this.accountId = newVal.id;
      } else {
        this.accountId = "";
      }
      this.showBtnSave();
    },
    specificBilling: function (val) {
      if (val != null && val != undefined) {
        // validate the same account
        if (this.accountId == "")
          if (this.accountId != val.internal_account_id) {
            this.$toasted.global.info({
              message: "El cobro seleccionado no pertenece a la cuenta interna",
            });
            return;
          }
        let data = {
          id: val.id,
          code: val.code,
          amount: val.amount,
          balance: val.balance,
          pay: 0,
        };
        this.addDetail(data);
      }
    },
    details: {
      handler: function (val) {
        this.showBillings = val.length > 0 ? true : false;
        this.totalBillings = 0;
        val.forEach((element) => {
          if (Number(element.balance) < Number(element.pay)) {
            element.pay = 0;
          }
          this.totalBillings += Number(element.pay);
        });
        this.totalBillings = this.totalBillings.toFixed(2);
        this.showBtnSave();
      },
      deep: true,
    },
    paymentMethod: {
      handler: function (val) {
        this.documentLabel = "No. Documento";

        if (val.type == "EFECTIVO") {
          this.documentLabel = "No. Control Efectivo";
          this.paymentMethod.document = val.description.toUpperCase();
        } else if (
          val.type == "CHEQUE" &&
          val.account != null &&
          val.account != undefined
        ) {
          this.paymentMethod.document =
            val.description + " | " + val.account.toUpperCase();
        } else {
          if (val.account != null && val.account != undefined && val.account != "") {
            this.paymentMethod.document =
              val.type == "TRANSFERENCIA"
                ? val.description +
                  " | " +
                  val.account.toUpperCase() +
                  "-" +
                  val.account_code
                : undefined;
          }
        }
      },
      deep: true,
    },
    paymentMethods: {
      handler: function (val) {
        this.showPayment = val.length > 0 ? true : false;
        this.totalPayment = 0;
        val.forEach((element) => {
          this.totalPayment += Number(element.amount);
        });
        this.totalPayment = this.totalPayment.toFixed(2);
        this.showBtnSave();
      },
      deep: true,
    },
  },
};
</script>
